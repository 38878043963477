import { omit } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
const ERROR_SET = 'apiException/ERROR_SET';
const ERROR_RESET = 'apiException/ERROR_RESET';
const doErrorSet = (stateKey, error) => createAction(ERROR_SET)({ stateKey, error });
const doErrorReset = stateKey => createAction(ERROR_RESET)({ stateKey });
const applyErrorSet = (state, { payload }) => (Object.assign(Object.assign({}, state), { [payload.stateKey]: payload.error }));
const applyErrorReset = (state, { payload }) => (Object.assign({}, omit(state, payload.stateKey)));
const reducer = handleActions({
    [ERROR_SET]: applyErrorSet,
    [ERROR_RESET]: applyErrorReset,
}, {});
const SLICE_NAME = 'apiExceptions';
const getException = (state, stateKey) => state[SLICE_NAME][stateKey] || null;
const actionCreators = { doErrorSet, doErrorReset };
const reducers = { [SLICE_NAME]: reducer };
const selectors = { getException };
export { SLICE_NAME, actionCreators, reducers, selectors };
