import axios from 'axios';
import { PUBLIC_API_URL } from '../../../../app-config/constants';
/**
 * @param userId This is necessary for submissions from logged out users, because
 *               we don’t have a session in the BE anymore from which we could
 *               obtain the user information.
 */
export function postError({ error, userId = '[UNKNOWN]', userText = '[EMPTY]' }) {
    return axios({
        url: PUBLIC_API_URL + '/support-requests',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
        data: {
            title: '[API-error]',
            text: `USER ID:\n${userId}\n\nMESSAGE FROM USER:\n${userText}\n\nERROR OBJECT:\n${JSON.stringify(error)}`,
        },
        credentials: 'same-origin',
    });
}
