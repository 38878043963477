// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MiniProfile-link-4TXvU {\n  display: flex;\n  align-items: center;\n}\n.MiniProfile-link-4TXvU img {\n  margin-right: 5px;\n  flex-shrink: 0;\n}\n.MiniProfile-inline-3nsiz {\n  display: inline;\n}\n.MiniProfile-inlineAvatarWrapper-2Z7EQ {\n  display: inline-flex;\n  vertical-align: middle;\n  align-items: center;\n  height: 1em;\n}\n.MiniProfile-inlineAvatarWrapper-2Z7EQ img {\n  transform: translateY(-10%);\n}\n.MiniProfile-ellipsizeWrapper-2zkWN {\n  overflow: hidden;\n}\n.MiniProfile-ellipsize-3ExFI {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.MiniProfile-deactivated-FHYSQ {\n  opacity: 0.5;\n}\n@media print {\n  .MiniProfile-deactivated-FHYSQ {\n    opacity: initial;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"link": "MiniProfile-link-4TXvU",
	"inline": "MiniProfile-inline-3nsiz",
	"inlineAvatarWrapper": "MiniProfile-inlineAvatarWrapper-2Z7EQ",
	"ellipsizeWrapper": "MiniProfile-ellipsizeWrapper-2zkWN",
	"ellipsize": "MiniProfile-ellipsize-3ExFI",
	"deactivated": "MiniProfile-deactivated-FHYSQ"
};
module.exports = exports;
