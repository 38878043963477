import { jsx as _jsx } from "react/jsx-runtime";
import { omit } from 'lodash/fp';
import * as React from 'react';
import { getCurrentUser } from '../../services/CurrentUser';
const appContext = {
    user: getCurrentUser(),
};
export function withAppContext(Component) {
    const newComponent = function (props) {
        return _jsx(Component, Object.assign({}, props, { appContext: appContext }));
    };
    newComponent.propTypes = omit('appContext', Component.propTypes);
    return newComponent;
}
