// Determines if a URL is relative to the current domain. Useful for determining if a URL is safe to redirect to.
export function isRelativeUrl(url) {
    try {
        const absoluteUrl = new URL(url, window.location.origin);
        return absoluteUrl.origin === window.location.origin;
    }
    catch (e) {
        return false;
    }
}
// Filters out URLs with the 'javascript:' protocol as these are primarily used for XSS.
export function preventJavascriptUrls(url) {
    try {
        const protocol = new URL(url, window.location.origin).protocol;
        // eslint-disable-next-line no-script-url
        return protocol === 'javascript:' ? '#' : url;
    }
    catch (e) {
        return url;
    }
}
