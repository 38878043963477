const sizes = {
    XS_WIDTH: 400,
    SM_WIDTH: 440,
    MD_WIDTH: 540,
    LG_WIDTH: 660,
    XL_WIDTH: 800,
    XXL_WIDTH: 980,
    AUTO: 'auto',
};
export default sizes;
