import { jsx as _jsx } from "react/jsx-runtime";
import { get } from 'lodash/fp';
import { createElement } from 'react';
import { connect } from 'react-redux';
import ApiError from '../../ApiError';
import ApiWarning from '../../ApiWarning';
import Modal from '../../Modal';
import { SPECIFIC_EXCEPTION_COMPONENTS } from '../RegisteredExceptions';
import { actionCreators, selectors } from './ducks';
const ApiExceptionInline = ({ error, stateKey = undefined }) => {
    if (!error) {
        return null;
    }
    const message = get('response.data.message')(error);
    const specificErrorComponent = SPECIFIC_EXCEPTION_COMPONENTS[stateKey];
    const hasSpecificErrorComponent = !!specificErrorComponent && !!specificErrorComponent[message];
    return hasSpecificErrorComponent ? (_jsx(ApiWarning, { children: createElement(SPECIFIC_EXCEPTION_COMPONENTS[stateKey][message], {}) })) : (_jsx(ApiError, { error: error }));
};
const ApiExceptionModalP = ({ stateKey, error, onErrorReset }) => (_jsx(Modal, { isOpen: !!error, onClose: onErrorReset, size: "MD_WIDTH", children: _jsx(ApiExceptionInline, { error: error, stateKey: stateKey }) }));
const mapStateToProps = (state, { stateKey }) => ({
    error: selectors.getException(state, stateKey),
    stateKey,
});
const mapDispatchToProps = (dispatch, { stateKey }) => ({
    onErrorReset: () => dispatch(actionCreators.doErrorReset(stateKey)),
});
const ApiExceptionModal = connect(mapStateToProps, mapDispatchToProps)(ApiExceptionModalP);
export { ApiExceptionInline, ApiExceptionModal };
