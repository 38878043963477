import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertTriangle, IconCheck } from 'featherico';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component, Fragment, createElement } from 'react';
import { withAppContext } from '../../hoc/withAppContext';
import Button from '../Button';
import DeprecatedButtonContainer from '../ButtonContainer';
import TextArea from '../TextArea';
import { postError } from './service';
const REQUEST_STATUS = {
    INIT: 'init',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
};
class ApiError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formVisible: false,
            formValue: '',
            requestStatus: REQUEST_STATUS.INIT,
        };
        this.sendSupportRequest = this.sendSupportRequest.bind(this);
    }
    sendSupportRequest(error, userId, userText) {
        this.setState({ requestStatus: REQUEST_STATUS.PENDING });
        postError({ error, userId, userText }).then(() => this.setState({ requestStatus: REQUEST_STATUS.SUCCEEDED }), () => this.setState({ requestStatus: REQUEST_STATUS.FAILED }));
    }
    render() {
        const { icon, title, body, hasBugReportLink } = getModel(this.state.requestStatus);
        const userId = get('user.id')(this.props.appContext);
        return (_jsxs("div", { className: "api_error", children: [_jsxs("h2", { children: [!icon ? (_jsx("img", { className: "api_error-icon--bot", src: "/images/si-bot-warning.svg", alt: "" })) : (createElement(icon)), title] }), _jsxs("p", { children: [createElement(body), hasBugReportLink ? (_jsx(ApiErrorBugReportLink, { onClick: () => this.setState({ formVisible: !this.state.formVisible }), isFormVisible: this.state.formVisible })) : ('')] }), hasBugReportLink && this.state.formVisible ? (_jsx(ApiErrorBugReportForm, { value: this.state.formValue, onChange: e => this.setState({ formValue: e.target.value }), onClickSubmit: () => this.sendSupportRequest(this.props.error, userId, this.state.formValue) })) : ('')] }));
    }
}
function getModel(requestStatus) {
    switch (requestStatus) {
        case REQUEST_STATUS.SUCCEEDED:
            return {
                icon: () => _jsx(IconCheck, { className: "api_error-icon api_error-icon--check" }),
                title: 'Thank You!',
                body: () => (_jsx(Fragment, { children: "We have received your bug report and will investigate the bug as soon as possible. For now, you could try to refresh your browser and try again." })),
                hasBugReportLink: false,
            };
        case REQUEST_STATUS.FAILED:
            return {
                icon: () => _jsx(IconAlertTriangle, { className: "api_error-icon api_error-icon--warning-sign" }),
                title: 'Sorry',
                body: () => (_jsxs(Fragment, { children: ["We could not send your report, please reach out to us directly via", ' ', _jsx("a", { href: "mailto:support@small-improvements.com", children: "support@small-improvements.com" }), "."] })),
                hasBugReportLink: false,
            };
        case REQUEST_STATUS.INIT:
        case REQUEST_STATUS.PENDING:
        default:
            return {
                title: 'Something went wrong',
                body: () => (_jsx(Fragment, { children: "Your last action couldn't be performed. Please make a copy of unsaved content, reload the page and try again." })),
                hasBugReportLink: false,
            };
    }
}
function ApiErrorBugReportLink({ onClick, isFormVisible }) {
    return (_jsxs(Fragment, { children: ["\u00A0If the error persists you can", ' ', isFormVisible ? (_jsx(Fragment, { children: "send a bug report." })) : (_jsxs(Fragment, { children: [_jsx(Button, { kind: "LINK", onClick: onClick, children: "send a bug report" }), "."] })), ' ', "We apologize for the inconvenience."] }));
}
function ApiErrorBugReportForm({ value, onChange, onClickSubmit }) {
    const textareaProps = {
        placeholder: 'E.g. I was trying to add a new user, and as I pressed the Add User button this error popped up.',
        rows: 5,
        value,
        onChange,
    };
    return (_jsxs("div", { children: [_jsx("h3", { children: "Please describe what you did" }), _jsx(TextArea, Object.assign({}, textareaProps)), _jsx(DeprecatedButtonContainer, { children: _jsx(Button, { kind: "PRIMARY", onClick: onClickSubmit, children: "Send bug report" }) })] }));
}
ApiError.propTypes = {
    // we expect error to look like axios response object
    error: PropTypes.shape({
        data: PropTypes.object,
        status: PropTypes.number,
        statusText: PropTypes.string,
        config: PropTypes.object,
    }).isRequired,
};
export default withAppContext(ApiError);
