import styles from './style.m.less';
const classNames = {
    XXS: styles.extraExtraSmall,
    XS: styles.extraSmall,
    SM: styles.small,
    MD: styles.medium,
    LG: styles.large,
    XL: styles.extraLarge,
    XXL: styles.extraExtraLarge,
    Full: styles.full,
};
export const getClassName = (size) => classNames[size];
const REG_EXP_SIZE_PARAMETER = new RegExp('\\b(size=).*?(&|$)');
export const replaceSizeParam = (url, size) => {
    if (/\.svg(\?|$)/.test(url)) {
        return url;
    }
    if (REG_EXP_SIZE_PARAMETER.test(url)) {
        return url.replace(REG_EXP_SIZE_PARAMETER, '$1' + size + '$2');
    }
    const paramJoiner = url.includes('?') ? '&' : '?';
    return `${url}${paramJoiner}size=${size}`;
};
export const getUrlForSize = ({ logo }, size) => {
    // Our default size for uploaded logos is 160px.
    // For now we use the default for all but the largest size.
    if (size !== 'Full') {
        return logo;
    }
    // -1 means don't scale
    return replaceSizeParam(logo, -1);
};
