import { pull } from 'lodash';
export default class PopupAlert {
    constructor() {
        this.model = {
            message: '',
            isOpen: false,
            status: undefined,
            surviveNavigation: false,
        };
        this.subscribers = [];
    }
    subscribe(listener) {
        this.subscribers.push(listener);
    }
    unsubscribe(listener) {
        pull(this.subscribers, listener);
    }
    setMessage(message, status, surviveNavigation = false) {
        this.model = {
            message,
            isOpen: true,
            status,
            surviveNavigation,
        };
        this.notify();
    }
    setSurviveNavigation(s) {
        this.model = Object.assign(Object.assign({}, this.model), { surviveNavigation: s });
    }
    setSuccessMessage(message, surviveNavigation = false) {
        this.setMessage(message, 'success', surviveNavigation);
    }
    setErrorMessage(message, surviveNavigation = false) {
        this.setMessage(message, 'error', surviveNavigation);
    }
    notify() {
        this.subscribers.forEach(s => s(this.getModel()));
    }
    dismiss() {
        this.model = {
            message: '',
            isOpen: false,
            status: undefined,
            surviveNavigation: false,
        };
        this.notify();
    }
    getModel() {
        return this.model;
    }
    isOpen() {
        return this.model.isOpen;
    }
}
