// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ApiWarning-container-3iEU- {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  color: #a3a2a1;\n  margin: 20px;\n}\n.ApiWarning-image-2VtP6 {\n  margin-bottom: 10px;\n  width: 50px;\n  height: 50px;\n  opacity: 0.5;\n}\n.ApiWarning-tilted-3RXqN {\n  transform: rotate(-10deg);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ApiWarning-container-3iEU-",
	"image": "ApiWarning-image-2VtP6",
	"tilted": "ApiWarning-tilted-3RXqN"
};
module.exports = exports;
