// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonContainer-container-3GAOR {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: end;\n  align-items: baseline;\n  gap: 10px;\n}\n.ButtonContainer-align-center-3N4zO {\n  justify-content: center;\n}\n.ButtonContainer-align-left-3kYpA {\n  justify-content: left;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ButtonContainer-container-3GAOR",
	"align-center": "ButtonContainer-align-center-3N4zO",
	"align-left": "ButtonContainer-align-left-3kYpA"
};
module.exports = exports;
