// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Link-reset-1JqaO {\n  color: inherit;\n}\n.Link-reset-1JqaO:hover,\n.Link-reset-1JqaO:active,\n.Link-reset-1JqaO:focus {\n  text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"reset": "Link-reset-1JqaO"
};
module.exports = exports;
