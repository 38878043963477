// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Avatar-extraExtraSmall-kYAhf {\n  width: 16px;\n  height: 16px;\n}\n.Avatar-extraSmall-3eX9t {\n  width: 20px;\n  height: 20px;\n}\n.Avatar-small-iHi9M {\n  width: 25px;\n  height: 25px;\n}\n.Avatar-medium-3CEyH {\n  width: 30px;\n  height: 30px;\n}\n.Avatar-large-2zipL {\n  width: 40px;\n  height: 40px;\n}\n.Avatar-extraLarge-2cLS0 {\n  width: 50px;\n  height: 50px;\n}\n.Avatar-extraExtraLarge-3XfVZ {\n  width: 75px;\n  height: 75px;\n}\n.Avatar-full-DQHpS {\n  width: 400px;\n  height: 400px;\n}\n.Avatar-round-1BxWw {\n  border-radius: 50%;\n}\n", ""]);
// Exports
exports.locals = {
	"extraExtraSmall": "Avatar-extraExtraSmall-kYAhf",
	"extraSmall": "Avatar-extraSmall-3eX9t",
	"small": "Avatar-small-iHi9M",
	"medium": "Avatar-medium-3CEyH",
	"large": "Avatar-large-2zipL",
	"extraLarge": "Avatar-extraLarge-2cLS0",
	"extraExtraLarge": "Avatar-extraExtraLarge-3XfVZ",
	"full": "Avatar-full-DQHpS",
	"round": "Avatar-round-1BxWw"
};
module.exports = exports;
