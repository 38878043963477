export default async function loadScript(url, id = null) {
    await new Promise((resolve, reject) => {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = url;
        scriptElement.async = true;
        if (id) {
            scriptElement.id = id;
        }
        scriptElement.onerror = reject;
        scriptElement.onload = resolve;
        document.head.appendChild(scriptElement);
    });
}
