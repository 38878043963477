import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { getClassName, getUrlForSize } from './service';
import styles from './style.m.less';
const Avatar = ({ user, size = 'MD', isRound = true, className, ariaHidden }) => {
    const sizeClass = getClassName(size);
    return (_jsx("img", { src: getUrlForSize(user, size), className: classNames({ [styles.round]: isRound }, sizeClass, className), title: ariaHidden ? undefined : user.name, alt: "", "aria-hidden": ariaHidden }));
};
Avatar.propTypes = {
    user: PropTypes.any.isRequired,
    size: PropTypes.any,
    isRound: PropTypes.bool,
    className: PropTypes.string,
    ariaHidden: PropTypes.bool,
};
export default Avatar;
